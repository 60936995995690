<template>
  <div class="w-screen h-screen flex flex-row">
    <div style="width:820px;" class="flex flex-col justify-center bg-gray-50">

      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <slot />
      </div>

    </div>
    <div class="bg-blue-300 w-full h-full"
         :style="'background: url(' + require('@/master/assets/b3.png') + '; background-size: cover;'"></div>
  </div>
</template>

<script>
export default {
  name: "AppLayoutLogin"
}
</script>

<style scoped>

</style>